$(function() {
	resizeFullScreenVideo();
	
	$('.js-main-nav').on('click', function() {
		$('#popUpMenu').addClass('pop-up-menu--is-opened');
	});
	
	$('.js-close-popup').on('click', function() {
		$('#popUpMenu').removeClass('pop-up-menu--is-opened');
	});
	
	
	// Simple Gallery switcher on product pages

    $('.js-gallery-thumb').on('click', function (e)
    {
        e.stopPropagation();
        var main = $($(this).data('target'));
        var orgMain = main.html();
        var thumb = $(this);
        var thumbHtml = thumb.html();

        thumb.html(orgMain);
        main.html(thumbHtml);

    });

	
});

$(window).resize(function() {
	resizeFullScreenVideo();
});

$(window).scroll(function() {
	if($(window).scrollTop() > 100) {
		$('.hamburger__label').addClass('hamburger__label--hidden');
	} else {
		$('.hamburger__label').removeClass('hamburger__label--hidden');
	}
});

function resizeFullScreenVideo(){
	if($(".fullscreen-video-container").length > 0) {
		var container = $(".fullscreen-video-container");
		var video = $('.fullscreen-video-container iframe');
		var originalRatio = 302/536;
		var t,e,i = $(window).width(),n=$(window).height(),s=185;
		n/i > originalRatio ? (t=n+s,video.height(t),e=(n+s)/originalRatio,video.width(e)) : (e=i+s*originalRatio,video.width(e), t=(i+s)*originalRatio,video.height(t)),

		setTimeout(function(){
			$(window).width() > 479 ? (container.width(i), container.height(n)) : (container.width(i), $("body.home").length> 0 ? container.height(n) : container.height(2*(n/3))), video.css("left",(i-e)/2),video.css("top",(n-t)/2)
			},10);
	}
}